.approval-opinion-dialog[data-v-565310dc] .el-dialog__body {
  padding: 0 20px !important;
}
[data-v-565310dc] .approvalCrud .avue-crud .el-table {
  height: calc(100vh - 374px) !important;
  max-height: calc(100vh - 374px) !important;
}
[data-v-565310dc] .el-button--text {
  color: #409EFF;
}
[data-v-565310dc] .approvalCrud .el-button:hover {
  color: #66b1ff !important;
  border-color: transparent !important;
  background-color: transparent !important;
}
[data-v-565310dc] .el-input__inner:focus {
  border-color: #409EFF;
}
[data-v-565310dc] .el-checkbox__inner:hover {
  border-color: #409EFF;
}
[data-v-565310dc] .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner[data-v-565310dc] {
  background-color: #409EFF;
  border-color: #409EFF;
}
[data-v-565310dc] .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #409EFF;
  border-color: #409EFF;
}
[data-v-565310dc] .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #409EFF;
}
[data-v-565310dc] .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #409EFF;
}
[data-v-565310dc] .el-pagination.is-background .el-pager li:not(.disabled).active {
  color: #FFFFFF;
  background-color: #409EFF;
}
[data-v-565310dc] .el-loading-spinner .path {
  stroke: #409EFF;
}
[data-v-565310dc] .el-select .el-input.is-focus .el-input__inner {
  border-color: #409EFF;
}
[data-v-565310dc] .el-pagination__sizes .el-input .el-input__inner:hover {
  border-color: #409EFF;
}